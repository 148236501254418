<template>
  <div class="columns is-multiline">
    <div class="column is-12 has-text-centered">
      <p class="subtitle">Últimos Productos</p>
    </div>
    <div class="column is-12" v-if="!loading">
      <div class="timeline">
        <header class="timeline-header">
          <span class="tag is-medium is-primary">
            <i class="material-icons">
              shopping_basket
            </i>
          </span>
        </header>
        <template v-for="product in productList">
          <div class="timeline-item" :key="product.productId">
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p class="heading">{{formatDateTimestamp(product.createdAt)}} </p>
              <p><router-link :to="{ name: 'detalle-producto', params: { productId: product.productId } }">{{product.username}} - {{product.name}}</router-link></p>
              <p class="timeline-price">$ (Precio): {{numberFormat(product.price)}}</p>
            </div>
          </div>
        </template>
      </div>
      <div>
        <router-link :to="{ name: 'productos' }" class="button button-givu">Ver Todos</router-link>
      </div>
    </div>
    <div class="column is-12" v-else>
      <loading-component />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import mixins from '../../mixin/validateDate'
export default {
  name: 'Timeline',
  mixins: [mixins],
  data () {
    return {
      productList: [],
      loading: false
    }
  },
  components: {
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  methods: {
    ...mapActions(['getProducts']),
    formatDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  beforeMount () {
    this.loading = true
    this.getProducts({ page: 1, filter: 0, limit: 5, sortKey: 'product.createdAt', sortOrder: 'desc' })
      .then(products => {
        this.productList = products.payload
        this.loading = false
      })
  }
}
</script>

<style scoped>
  .heading, .timeline-price {
    color: #4d4f5c;
    font-weight: 100 !important;
  }
  .timeline-price{
    color: #4d4f5c;
    font-size:12px;
  }
  a {
    color: var(--secondary-color);
  }
  .button-givu {
    color: #FFFFFF;
  }
</style>
